<div class="user-profile user-card mb-4" *ngIf="owner">
    <div class="card-body py-0">
      <div class="user-about-block m-0">
        <div class="row">
          <div class="col-md-3 text-center mt-n5">
            <div class="change-profile text-center">
              <div class="dropdown w-auto d-inline-block" ngbDropdown>
                <a class="dropdown-toggle" data-toggle="dropdown" ngbDropdownToggle aria-haspopup="true"
                  aria-expanded="false">
                  <div class="profile-dp">
                    <div class="position-relative d-inline-block">
                      <img *ngIf="owner?.type === 'PARTICULIER' && owner?.sexe === 'Masculin'"
                        class="img-radius img-fluid wid-80 hei-80"
                        [src]="owner.photoSrc ? publicUrl+'/'+owner.photoSrc : 'assets/images/avatar-default.png'"
                        onerror="this.onerror=null; this.src='assets/images/avatar-default.png'" />
                      <img *ngIf="owner?.type === 'PARTICULIER' && owner?.sexe === 'Féminin'"
                        class="img-radius img-fluid wid-80 hei-80"
                        [src]="owner.photoSrc ? publicUrl+'/'+owner.photoSrc : 'assets/images/avatar-mlle.jpeg'"
                        onerror="this.onerror=null; this.src='assets/images/avatar-mlle.jpeg'" />
                      <img *ngIf="owner?.type === 'ENTREPRISE' && owner?.photoSrc"
                        class="img-radius img-fluid wid-80 hei-80"
                        [src]="owner.photoSrc ? publicUrl+'/'+owner.photoSrc : 'assets/images/avatar-default.png'"
                        onerror="this.onerror=null; this.src='assets/images/avatar-default.png'" />
                      <i *ngIf="owner?.type === 'ENTREPRISE' && !owner?.photoSrc" class="fas fa-building fa-5x"></i>
                    </div>
                    <div class="overlay">
                      <span>change</span>
                    </div>
                  </div>
                  <div class="certificated-badge">
                    <i class="fas fa-certificate text-c-blue bg-icon"></i>
                    <i class="fas fa-check front-icon text-white"></i>
                  </div>
                </a>
              </div>
            </div>
            <h5 class="mb-1">{{ owner?.nom }}</h5>
            <p class="mb-2 text-muted text-uppercase">{{ owner?.type }}</p>
          </div>
          <div class="col-md-9 mt-md-4">
            <div class="row">
              <div class="col-md-3">
                <p class="mb-1 text-muted d-flex align-items-end text-h-primary">
                  <i class="fas fa-business-time mr-2"></i>{{owner?.profession}}
                </p>
                <div class="clearfix"></div>
                <p class="mb-1 text-muted d-flex align-items-end text-h-primary">
                  <i class="feather icon-phone mr-2 f-18"></i>{{owner?.telephone}}
                </p>
              </div>
              <div class="col-md-3">
                <div class="media">
                  <i class="feather icon-map-pin mr-2 mt-1 f-18"></i>
                  <div class="media-body">
                    <p class="mb-0 text-muted">{{owner?.domicile}}</p>
                  </div>
                </div>
                <div class="media">
                  <i class="feather icon-mail mr-2 f-18"></i>
                  <div class="media-body">
                    <p class="mb-0 text-muted">{{owner?.email}}</p>
                  </div>
                </div>
              </div>
             
            </div>
            <!-- MENU TABS PROPRIETAIRE -->
            <ul class="nav nav-tabs profile-tabs nav-fill" id="tabShowOwner" role="tablist">
           
              <li class="nav-item">
                <a class="nav-link text-reset" [ngClass]="{'active': this.activeTab === 'COPROPRIETAIRE'}"
                  (click)="onChangeLoad('COPROPRIETAIRE')" id="profil-tab" data-toggle="tab" href="javascript:" role="tab"
                  aria-controls="profil" aria-selected="true">
                  <i class="far fa-user"></i> Profil
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link text-reset" [ngClass]="{'active': activeTab === 'SYNDIC'}" (click)="onChangeLoad('SYNDIC')"
                  id="bien-tab" data-toggle="tab" href="javascript:" role="tab" aria-controls="bien" aria-selected="true">
                  <i class="feather icon-layers"></i> syndic
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link text-reset" [ngClass]="{'active': this.activeTab === 'LOT'}"
                  (click)="onChangeLoad('LOT')" id="lot-tab" data-toggle="tab" href="javascript:" role="tab"
                  aria-controls="lot" aria-selected="false">
                  <i class="fas fa-home"></i> Lots
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link text-reset" [ngClass]="{'active': this.activeTab === 'APPEL'}"
                  (click)="onChangeLoad('APPEL')" id="appel-tab" data-toggle="tab" href="javascript:" role="tab"
                  aria-controls="appel" aria-selected="false">
                  <i class="fas fa-money-bill-wave"></i> Appels de charge
                </a>
              </li>

              <li class="nav-item">
                <a class="nav-link text-reset" [ngClass]="{'active': this.activeTab === 'PROVISION'}"
                  (click)="onChangeLoad('PROVISION')" id="appel-tab" data-toggle="tab" href="javascript:" role="tab"
                  aria-controls="appel" aria-selected="false">
                  <i class="fas fa-money-bill-wave"></i> Réglements
                </a>
              </li>

              
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <!-- RETOUR -->
  <div class="row">
    <div class="col-sm-12 mb-2">
      <button (click)="back()" type="button" class="btn btn-secondary mr-1">
        <i class="fa fa-arrow-alt-circle-left"></i> Retour
      </button>
    </div>
  </div>
  
  <!-- FILTRE DE RECHERCHE -->
  <div class="row" *ngIf=" activeTab !== 'TICKET' &&  activeTab != 'COPROPRIETAIRE' && activeTab != 'SYNDIC'  ">
    <ng-template
      [ngxPermissionsOnly]="['HOUSE:LIST', 'RENTAL:LIST', 'MANDATE:LIST', 'PAYMENT:REPAYMENT:LIST', 'REPAYMENT:LIST', 'RENEW:MANDATE:LIST']">
      <app-filter class="width" [name]="name" [nameTitle]="nameTitle" [user]="true" [userTitle]="userTitle" [etat]="etat"
        [etatRow]="etatRow" [etatTitle]="etatTitle" [categorie]="categorie" [categorieRow]="categorieRow"
        [categorieTitle]="categorieTitle" [dateD]="true" [dateF]="true" [create]="true" [min]="true" [minTitle]="minTitle"
        [max]="true" [maxTitle]="maxTitle" [ordre]="true" [code]="true" [count]="true" [type]="true" [typeRow]="typeRow"
        [uuid]="owner?.uuid" (filterEvent)="onFilter($event)">
      </app-filter>
    </ng-template>
    <ng-template
      [ngxPermissionsExcept]="['HOUSE:LIST', 'RENTAL:LIST', 'MANDATE:LIST', 'PAYMENT:REPAYMENT:LIST', 'REPAYMENT:LIST', 'RENEW:MANDATE:LIST']">
      <div class="col-md-12">
        <app-no-droit [title]="'propriétaire'"></app-no-droit>
      </div>
    </ng-template>
  </div>
  
  <!-- AFFICHAGE DU TABS -->
  <div class="row">
    <div class="col-md-12 order-md-2">
      <div class="tab-content" id="myTabContent">
  
        <div class="tab-pane fade" [ngClass]="{'show active': activeTab === 'COPROPRIETAIRE'}" id="profil" role="tabpanel"
          aria-labelledby="profil-tab">
          <div class="card">
            <div class="col-md-12 mt-2 text center">
                
              <div class="d-flex">
                <!-- COL GAUCHE -->
                <div class="flex-fill">
                  <p>
                    <span class="title-show">Référence : </span>
                    <span class="title-result-show">{{ owner?.code }}</span>
                  </p>
                  <p>
                    <span class="title-show">
                      {{ owner?.type === 'ENTREPRISE' ? 'Raison sociale' : 'Nom et prénoms' }} :
                    </span>
                    <span class="title-result-show">
                      {{ owner?.type === 'ENTREPRISE' ? owner?.nom : owner?.civilite + ' ' + owner?.nom }}
                    </span>
                  </p>
                  <p>
                    <span class="title-show">Type : </span>
                    <span class="title-result-show">{{ owner?.type }}</span>
                  </p>
                  <p>
                    <span class="title-show">Email : </span>
                    <span class="title-result-show">{{ owner?.email }}</span>
                  </p>
                  <p>
                    <span class="title-show">Telephone : </span>
                    <span class="title-result-show">{{ owner?.telephone }}</span>
                  </p>
                  <p>
                    <span class="title-show">Telephone whatsapp  : </span>
                    <span class="title-result-show">{{ owner?.numbWhatsapp }}</span>
                  </p>
                  <p>
                    <span class="title-show">
                      {{ owner?.type === 'ENTREPRISE' ? 'Siège social' : 'Domicile' }} :
                    </span>
                    <span class="title-result-show">
                      {{ owner?.type === 'ENTREPRISE' ? owner?.siegeSocial : owner?.domicile }}
                    </span>
                  </p>
                  <p>
                    <span class="title-show">Code postal : </span>
                    <span class="title-result-show">{{ owner?.codePostal }}</span>
                  </p>
                  <p>
                    <span class="title-show">
                      {{ owner?.type === 'ENTREPRISE' ? "Domaine d'activité" : 'Profession' }} :
                    </span>
                    <span class="title-result-show">{{ owner?.profession }}</span>
                  </p>
                  <p *ngIf="owner?.type === 'ENTREPRISE'">
                    <span class="title-show">Capital : </span>
                    <span class="title-result-show">{{ owner?.capital }}</span>
                  </p>
                </div>
                <!-- COL MILLIEU -->
                <div class="flex-fill">
                  <p *ngIf="owner?.type === 'ENTREPRISE'">
                    <span class="title-show">Nom du responsable: </span>
                    <span class="title-result-show">{{ owner?.civilite + ' ' + owner?.nomResponsable }}</span>
                  </p>
                  <p>
                    <span class="title-show">
                      {{ owner?.type === 'ENTREPRISE' ? 'Date naissance du responsable' : 'Date naissance' }} :
                    </span>
                    <span class="title-result-show">{{ owner?.dateN | date: "d MMMM y" : '' : 'fr-FR' }}</span>
                  </p>
                  <p>
                    <span class="title-show">
                      {{ owner?.type === 'ENTREPRISE' ? 'Lieu de naissance du responsable' : 'Lieu de naissance' }} :
                    </span>
                    <span class="title-result-show">{{ owner?.lieuN }}</span>
                  </p>
                  <p>
                    <span class="title-show">
                      {{ owner?.type === 'ENTREPRISE' ? 'Nationnalité du responsable' : 'Nationnalité' }} :
                    </span>
                    <span class="title-result-show">{{ owner?.nationalite }}</span>
                  </p>
                  <p *ngIf="owner?.type === 'ENTREPRISE' || owner?.type === 'PARTICULIER' ">
                    <span class="title-show">N° Compte contribualbe: </span>
                    <span class="title-result-show">{{ owner?.ncc }}</span>
                  </p>
                  <p *ngIf="owner?.type === 'ENTREPRISE'">
                    <span class="title-show">N° Registre de commerce: </span>
                    <span class="title-result-show">{{ owner?.nrc }}</span>
                  </p>
                  <p *ngIf="owner?.type === 'ENTREPRISE'">
                    <span class="title-show">Telephone du responsable: </span>
                    <span class="title-result-show">{{ owner?.telResponsable }}</span>
                  </p>
                  <p *ngIf="owner?.type === 'ENTREPRISE'">
                    <span class="title-show">Poste du responsable: </span>
                    <span class="title-result-show">{{ owner?.postOccupe }}</span>
                  </p>
                  <p>
                    <span class="title-show">
                      {{ owner?.type === 'ENTREPRISE' ? 'Sexe du responsable' : 'Sexe' }} :
                    </span>
                    <span class="title-result-show">{{ owner?.sexe }}</span>
                  </p>
                  <p *ngIf="owner?.type === 'PARTICULIER'">
                    <span class="title-show">Situation matrimonial: </span>
                    <span class="title-result-show">{{ owner?.situationMatrimoniale }}</span>
                  </p>
                  <p *ngIf="owner?.type === 'PARTICULIER'">
                    <span class="title-show">Nombre d'enfant: </span>
                    <span class="title-result-show">{{ owner?.enfant }}</span>
                  </p>
                  <p *ngIf="owner?.type === 'PARTICULIER'">
                    <span class="title-show">Nom conjoint: </span>
                    <span class="title-result-show">{{ owner?.conjoint }}</span>
                  </p>
                  <p *ngIf="owner?.type === 'PARTICULIER'">
                    <span class="title-show">Releve d'indetite bancaire(RIB): </span>
                    <span class="title-result-show">{{ owner?.rib }}</span>
                  </p>
                  <p *ngIf="owner?.type === 'PARTICULIER'">
                    <span class="title-show">Nom employeur: </span>
                    <span class="title-result-show">{{ owner?.employeur }}</span>
                  </p>
                  <p *ngIf="owner?.type === 'PARTICULIER'">
                    <span class="title-show">Personne en cas d'urgence : </span>
                    <span class="title-result-show">{{ owner?.nomUrgence }}</span>
                  </p>
                  <p *ngIf="owner?.type === 'PARTICULIER'">
                    <span class="title-show">Numero d'urgence : </span>
                    <span class="title-result-show">{{ owner?.contactUrgence }}</span>
                  </p>
                </div>
                <!-- COL DROITE -->
                <div class="flex-fill">
                  <p *ngIf="owner?.type === 'PARTICULIER'">
                    <span class="title-show">Affinité partagée : </span>
                    <span class="title-result-show">{{ owner?.affiniteUrgence}}</span>
                  </p>
                  <p>
                    <span class="title-show">Nature de la pièce: </span>
                    <span class="title-result-show">{{ owner?.naturePiece }}</span>
                  </p>
                  <p>
                    <span class="title-show">Numéro de la pièce: </span>
                    <span class="title-result-show">{{ owner?.numPiece }}</span>
                  </p>
                  <p>
                    <span class="title-show">Validité de la pièce : </span>
                    <span class="title-result-show">{{ owner?.dateExpirePiece | date: "d MMMM y" : '' : 'fr-FR' }}</span>
                  </p>
                  <p>
                    <span class="title-show">Date d'émission: </span>
                    <span class="title-result-show">{{ owner?.dateEmission | date: "d MMMM y" : '' : 'fr-FR' }}</span>
                  </p>
                  <p>
                    <span class="title-show">Signature de l'autorité: </span>
                    <span class="title-result-show">{{ owner?.signatureAutorite }}</span>
                  </p>
                  <p>
                    <span class="title-show">Crée le : </span>
                    <span class="title-result-show">{{ owner?.createdAt| date: "d MMMM y 'à' h:mm:ss" : '' :
                      'fr-FR'}}</span>
                    <span class="title-show ml-5"> Par : </span>
                    <span class="title-result-show">{{ owner?.create }}</span>
                  </p>
                  <p>
                    <span class="title-show">Modifié le : </span>
                    <span class="title-result-show">{{ owner?.updatedAt| date: "d MMMM y 'à' h:mm:ss" : '' :
                      'fr-FR'}}</span>
                    <span class="title-show ml-5"> Par : </span>
                    <span class="title-result-show">{{ owner?.update }}</span>
                  </p>
                </div>
              </div>
              <div class="col-md" *ngIf="owner">
                <span class="badge badge-primary mb-4 f-14 width">FICHE DE DOSSIER</span>
                <div class="row">
                  <div [ngClass]="file ? 'col-md-4' : 'col-md-12'">
                    <app-folder-uploader (click)="showFile(owner?.folder)" [maxSize]="3" [folder]="owner?.folder" [etat]="'show'"
                      [allowFileUpload]="false" [allowDownload]="true">
                    </app-folder-uploader>
                  </div>
                  <div [ngClass]="file ? 'col-md-8' : ''" *ngIf="file">
                    <div class="row">
                      <div class="col-sm-12 mb-2">
                        <button (click)="closeViewer()" type="button" class="btn btn-secondary m-1">
                          <i class="fa fa-arrow-alt-circle-left"></i> Fermer
                        </button>
                      </div>
                    </div>
                    <ngx-doc-viewer [url]="file" viewer="url" style="width:100%;height: 64vh;">
                    </ngx-doc-viewer>
  
                  </div>
                </div>
  
              </div>
              <div class="modal-footer">
                <button *ngxPermissionsOnly="'OWNER:EDIT'" (click)="editOwner(owner)" type="button"
                  class="btn btn-primary" ngbTooltip="Modifier">
                  Modifier <i class="feather icon-edit-2"></i>
                </button>
                <button *ngxPermissionsOnly="'OWNER:PRINTER:SHOW'" (click)="printerOwner(owner)" type="submit"
                  class="btn btn-warning" ngbTooltip="Imprimer">
                  Imprimer <i class="feather icon-printer"></i>
                </button>
                <ng-template ngxPermissionsOnly="OWNER:DELETE">
                  <button *ngIf="owner?.isDelete" (click)="delete(owner)" type="button"
                    class="btn btn-danger text-left" data-dismiss="modal" ngbTooltip="Supprimer">
                    Supprimer <i class="feather icon-trash"></i>
                  </button>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
  
        <div class="tab-pane fade" [ngClass]="{'show active': activeTab === 'SYNDIC'}" id="bien" role="tabpanel"
          aria-labelledby="bien-tab">
          <app-card [hidHeader]="true" cardClass="card-datatable">
            <span class="badge badge-primary mb-4 f-14 width">
                DÉTAIL SUR LE SYNDIC
            </span>
            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-6">
                      <div class="row py-2 item-condominium">
                        <div class="col-md-5">
                            <span>
                              Reférence
                            </span>
                        </div>
                        <div class="col-md-7">
                            <span class="bold">
                              {{syndic?.code}}
                            </span>
                        </div>
                      </div>
                      <div class="row py-2">
                        <div class="col-md-5">
                            <span>
                              Nom du syndic
                            </span>
                        </div>
                        <div class="col-md-7">
                            <span class="bold">
                              {{syndic?.nom}}
                            </span>
                        </div>
                      </div>
                      <div class="row py-2 item-condominium">
                        <div class="col-md-5">
                            <span>
                              Pays :
                            </span>
                        </div>
                        <div class="col-md-7">
                            <span class="bold">
                              {{syndic?.pays?.nom }}
                            </span>
                        </div>
                      </div>
                      <div class="row py-2">
                        <div class="col-md-5">
                            <span>
                              Ville :
                            </span>
                        </div>
                        <div class="col-md-7">
                            <span class="bold">
                              {{syndic?.ville }}
                            </span>
                        </div>
                      </div>
                      <div class="row py-3 item-condominium">
                        <div class="col-md-5">
                            <span>Mode de cotisation :</span>
                        </div>
                        <div class="col-md-7">
                            <span *ngIf="syndic?.mode == 'MONTANT_FIXE'" class="bold">Montant Fixe</span>
                            <span *ngIf="syndic?.mode == 'TANTIEME'" class="bold">Tantième</span>
                        </div>
                      </div>
                      <div *ngIf="syndic?.mode == 'MONTANT_FIXE'" class="row py-3">
                        <div class="col-md-5">
                            <span>Montant de la cotisation :</span>
                        </div>
                        <div class="col-md-7">
                            <span class="bold">{{syndic?.montantCotisation }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="row py-2">
                        <div class="col-md-5">
                            <span>
                              Commune / Quartier :
                            </span>
                        </div>
                        <div class="col-md-7">
                            <span class="bold">
                              {{ syndic?.commune }} - {{ syndic?.quartier }}
                            </span>
                        </div>
                      </div>
                      <div class="row py-2 item-condominium">
                        <div class="col-md-5">
                            <span>
                              Date de création : <br />
                            </span>
                        </div>
                        <div class="col-md-7">
                            <span class="bold">
                              {{syndic?.createdAt| date: "d MMMM y 'à' H:mm:ss " : '' : 'fr-FR'}}<br />
                            </span>
                        </div>
                      </div>
                      <div class="row py-2">
                        <div class="col-md-5">
                            <span>
                              Créé par
                            </span>
                        </div>
                        <div class="col-md-7">
                            <span class="bold">
                              {{syndic?.create}}
                            </span>
                        </div>
                      </div>
                      <div class="row py-2 item-condominium">
                        <div class="col-md-5">
                            <span>
                              Date de modification : <br />
                            </span>
                        </div>
                        <div class="col-md-7">
                            <span class="bold">
                              {{syndic?.updatedAt| date: "d MMMM y 'à' H:mm:ss " : '' : 'fr-FR'}}<br />
                            </span>
                        </div>
                      </div>
                      <div class="row py-2">
                        <div class="col-md-5">
                            <span>
                              Modifié par
                            </span>
                        </div>
                        <div class="col-md-7">
                            <span class="bold">
                              {{syndic?.update}}
                            </span>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
  
  
            <div class="col-md" *ngIf="syndic">
              <span class="badge badge-primary mb-4 f-14 width">FICHE DE DOSSIER</span>
              <div class="row">
                <div [ngClass]="file ? 'col-md-4' : 'col-md-12'">
                  <app-folder-uploader (click)="showFile(syndic?.folder)" [folder]="syndic?.folder" [etat]="'show'"
                    [allowFileUpload]="false" [allowDownload]="true">
                  </app-folder-uploader>
                </div>
                <div [ngClass]="file ? 'col-md-8' : ''" *ngIf="file">
                  <div class="row">
                    <div class="col-sm-12 mb-2">
                      <button (click)="closeViewer()" type="button" class="btn btn-secondary m-1">
                        <i class="fa fa-arrow-alt-circle-left"></i> Fermer
                      </button>
                    </div>
                  </div>
                  <ngx-doc-viewer [url]="file" viewer="url" style="width:100%;height: 64vh;">
                  </ngx-doc-viewer>
                </div>
              </div>
            </div>
          
        </app-card>
        </div>
  
        <div class="tab-pane fade" [ngClass]="{'show active': activeTab === 'LOT'}" id="lot" role="tabpanel"
          aria-labelledby="lot-tab">
          <div class="row">
            <!-- LISTE DES LOCATIVES -->
            <div class="col-md-12" >
              <span class="badge badge-info mb-4 f-14 width">
                LISTE DES LOTS
              </span>
              <app-no-data [title]="'Aucun lot trouvé'" *ngIf="coproprietes && coproprietes.length === 0"></app-no-data>
            </div>
            <div *ngIf="coproprietes && coproprietes.length > 0" class="row">
                <div class="col-lg-4 col-md-4" *ngFor="let item of coproprietes">
                    <app-card [customHeader]="true" headerClass="border-0 p-2 pb-0" [options]="false" cardClass="user-card user-card-1" blockClass="pt-0 users-contact">
                        <div class="app-card-header" (click)="showHouse(item)">
                            <div class="row align-items-end">
                                <div *ngIf="item?.type !== 'VERTICAL'" class="col text-left pb-3">
                                  <span>{{item?.owner?.nom}}</span> <br />
                                </div>
                                <div class="col text-right pb-3">
                                  <span class="badge badge-light-warning ml-1">
                                    <span *ngIf="item?.categorie">
                                      {{item?.categorie === 'APPARTEMENT' ? 'Appartement ' : item?.categorie === 'BUREAU' ? 'Bureau' : item?.categorie === 'MAGASIN' ? 'Magasin' : item?.categorie === 'SURFACE' ? 'Surface' : item?.categorie === 'RESTAURANT' ? 'Restaurant' : item?.categorie === 'HALL' ? 'Hall' : item?.categorie === 'SALLE CONFERENCE' ? 'Salle de conférence' : item?.categorie === 'PARKING' ? 'Parking' : item?.categorie === 'VILLA' ? 'Villa' : item?.categorie === 'STUDIO' ? 'Studio' : ''}}
                                    </span>
                                    <span *ngIf="item?.type === 'VERTICAL'">
                                      Vertical
                                    </span>
                                    <span *ngIf="item?.type === 'HORIZONTAL' && item?.categorie === 'HORIZONTAL'">Horizontal</span>
                                  </span>
                                </div>
                            </div>
                          <div class="cover-img-block condominium-pic">
                            <img [src]="item?.photoSrc ? publicUrl+'/'+item.photoSrc : 'assets/images/house-default.png'" alt="{{ item?.nom }}"
                            title="{{ item?.nom }}" class="img-fluid">
                          </div>
                        </div>
                        <div class="text-center" (click)="showHouse(item)">
                          <div class="row align-items-end">
                          </div>
                          <div class="alert-info mb-1">
                            <div class="col-md-12 mt-2 text-center p-2">
                              <h5 class="bold">
                                Lot : {{item?.nom}}
                              </h5>
                            </div>
                          </div>
                          <h6 class="mb-1 mt-3">
                            Syndic : {{item?.trustee?.nom}}
                          </h6>
                          <h6 class="mb-1 mt-3">
                            Copropriétaire : {{item?.ownerCo?.nom}} 
                          </h6>
                          <h6 class="mb-1 mt-3">
                            <span>
                              Superficie : {{item?.superficie}} (m²)
                            </span>
                            <span *ngIf="item?.type !== 'VERTICAL'">
                              - Numéro de porte : {{item?.numPorte}}
                            </span>
                          </h6>
                          <hr *ngIf="item?.tantiemes">
                          <h5 *ngIf="item?.tantiemes">Tantième(s)</h5>
                          <h6 *ngFor="let tantieme of item?.tantiemes">
                            <span>{{tantieme?.type}} - </span>
                            <span> valeur : {{tantieme?.valeur | number}} </span>
                          </h6>
                        </div>
                        <hr>
                        <div class="row mb-2" (click)="showHouse(item)">
                          <div class="col text-left">
                            <p class="mb-0">Code : {{item?.code}}</p>
                          </div>
                        </div>
                        <div class="row align-content">
                            <div class="col text-right">
                              <ng-template [ngxPermissionsOnly]="['HOMECO:SHOW']">
                                <button (click)="showHouse(item)" type="button" class="btn btn-icon btn-secondary" ngbTooltip="Détails">
                                  <i class="fas fa-eye"></i>
                                </button>
                              </ng-template>
                              <ng-template [ngxPermissionsOnly]="['HOMECO:EDIT']">
                                <button (click)="editHouse(item)" type="button" class="btn btn-icon btn-primary ml-1" ngbTooltip="Modifier">
                                  <i class="feather icon-edit-2"></i>
                                </button>
                              </ng-template>
                              <ng-template [ngxPermissionsOnly]="['HOMECO:PRINTER:SHOW']">
                                <button (click)="printHouse(item)" type="button" class="btn btn-icon btn-warning ml-1" ngbTooltip="Imprimer">
                                  <i class="feather icon-printer"></i>
                                </button>
                              </ng-template>
                              <ng-template [ngxPermissionsOnly]="['HOMECO:DELETE']">
                                <button (click)="deleteHouse(item)" type="button" class="btn btn-icon btn-danger ml-1" ngbTooltip="Supprimer">
                                  <i class="feather icon-trash"></i>
                                </button>
                              </ng-template>
                       
                            </div>
                          </div>
                       
                    </app-card>
                </div>
              </div>
          </div>
        </div>
  
        <div class="tab-pane fade" [ngClass]="{'show active': activeTab === 'APPEL'}" id="mandat" role="tabpanel"
          aria-labelledby="appel-tab">
          <div class="row">
            <!-- LISTE DES LOCATIVES -->
            <div class="col-md-12" >
              <span class="badge badge-warning mb-4 f-14 width">
                LISTE DES APPELS DE CHARGE
              </span>
            </div>
            <div class="col-md-12">
                <app-no-data [title]="'Aucun appel de charge trouvé'" *ngIf="fundsapeals && fundsapeals.length === 0"></app-no-data>
                <app-funds-apeals-list *ngIf="fundsapeals && fundsapeals.length > 0" [fundsapeals]="fundsapeals" [montant]="montant" [paye]="paye" [reste]="reste"></app-funds-apeals-list>
      
            </div>
          </div>
        </div>

        <div class="tab-pane fade" [ngClass]="{'show active': activeTab === 'PROVISION'}" id="mandat" role="tabpanel"
        aria-labelledby="appel-tab">
        <div class="row">
            <!-- LISTE DES PROVISIONS -->
          <div class="col-md-12" >
            <span class="badge badge-primary mb-4 f-14 width">
                LISTE DES RÈGLEMENTS
            </span>
          </div>
          <div class="col-md-12">
            <app-no-data [title]="'Aucun règlement trouvé'" *ngIf="fundsPayments && fundsPayments.length === 0"></app-no-data>
            <app-funds-payment-list *ngIf="fundsPayments && fundsPayments.length > 0" [fundsPayments]="fundsPayments"></app-funds-payment-list>
        
          </div>
        </div>
      </div>


    
        <div class="tab-pane fade" [ngClass]="{'show active': activeTab === 'RETRAIT'}" id="reversement"
          role="tabpanel" aria-labelledby="reversement-tab">
          <div class="row">
            <!-- LISTE DES RETRAIT PORTEFEUILLE -->
            <div class="col-md-12" *ngIf="withdrawlls">
              <span class="badge badge-success mb-4 f-14 width">
                LISTE DES RETRAIT DU PORTEFEUILLE
              </span>
              <app-no-data [title]="'Aucun retrait trouvé'" *ngIf="withdrawlls && withdrawlls.length === 0">
              </app-no-data>
            </div>
            <app-owner-withdrawll *ngIf="withdrawlls && withdrawlls.length > 0" [withdrawlls]="withdrawlls" class="width">
            </app-owner-withdrawll>
          </div>
        </div>
        <div class="tab-pane fade" [ngClass]="{'show active': activeTab === 'DEPOT'}" id="reversement"
          role="tabpanel" aria-labelledby="reversement-tab">
          <div class="row">
            <!-- LISTE DES DEPOT PORTEFEUILLE -->
            <div class="col-md-12" *ngIf="deposits">
              <span class="badge badge-success mb-4 f-14 width">
                LISTE DES DEPOTS DU PORTEFEUILLE
              </span>
              <app-no-data [title]="'Aucun dépôt trouvé'" *ngIf="deposits && deposits.length === 0">
              </app-no-data>
            </div>
            <app-owner-deposit *ngIf="deposits && deposits.length > 0" [deposits]="deposits" class="width">
            </app-owner-deposit>
          </div>
        </div>
  
        <!-- LISTE DES NOTES_INTERNES -->
        <div class="width list-notes-internes" *ngIf="activeTab === 'NOTE_INTERNE'">
          <app-activity-list [notes]="notes" [rdvs]="rdvs"></app-activity-list>
        </div>
  
        <div class="tab-pane fade" [ngClass]="{'show active': activeTab === 'TICKET'}" id="ticket"
        role="tabpanel" aria-labelledby="reversement-tab">
        <div class="row">
          <!-- LISTE DES TICKETS -->
          <div class="col-md-12" *ngIf="tickets">
            <span class="badge badge-success mb-4 f-14 width">
              LISTE DES TICKETS
            </span>
            <app-no-data [title]="'Aucun ticket trouvé'" *ngIf="tickets && tickets.length === 0">
            </app-no-data>
          </div>
          <app-ticket-table *ngIf="tickets && tickets.length > 0" [tickets]="tickets" class="width">
          </app-ticket-table>
        </div>
      </div>
  
  
      <div class="tab-pane fade" [ngClass]="{'show active': activeTab === 'WALLET'}" id="wallet" role="tabpanel"
      aria-labelledby="wallet-tab">
        <app-owner-wallet  class="width" [widget]="widget" [listing]="listing" [graph]="graph" [owner]="owner"> </app-owner-wallet>
      </div>
  
      </div>
    </div>
  </div>
  