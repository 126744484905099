import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EmitterService } from '@service/emitter/emitter.service';
import { VilleService } from '@service/ville/ville.service';
import { CommonService } from '@service/common/common.service'
import { Globals } from '@theme/utils/globals';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { Common } from '@model/common';
import { Ville } from '@model/ville';

@Component({
  selector: 'app-common-add',
  templateUrl: './common-add.component.html',
  styleUrls: ['./common-add.component.scss']
})
export class CommonAddComponent implements OnInit {
  type: string = ""
  title: string = ""
  citySelected: any
  edit: boolean = false
  common: Common
  ville: Ville
  form: FormGroup
  submit: boolean = false
  required = Globals.required;
  formSearch: any;
  citys: Ville[]
  @Output() commonUpdated = new EventEmitter<void>();

  
  constructor(
    public modal: NgbActiveModal,
    public toastr: ToastrService,
    private formBuild: FormBuilder,
    private emitter: EmitterService,
    private commonService: CommonService,
    private villeService: VilleService
  ) {
    this.edit = this.commonService.edit
    this.common = this.commonService.getCommon()
    this.title = (!this.edit) ? "Ajouter une commune" : "Modifier la commune "+this.common.libelle
    this.newForm()
  }

  ngOnInit(): void {
    this.loadCitys();
    this.editForm()
  }

  newForm() {
    this.form =  this.formBuild.group({
      uuid: [null],
      id: [null],
      city: [null, [Validators.required]],
      libelle: [null, [Validators.required]],
      
    })
  }
 
  editForm() {
    if (this.edit) {
      const data = { ...this.commonService.getCommon() }
      
    }
  }
  loadCitys() {
    // if (!this.edit) {
      this.villeService.getList().subscribe(res => {
        this.citys = res;
        return this.citys;

      }, error => { });
      this.form.get('libelle').reset();
    // }
  }
  selectCity(value) {
    this.citys = [];
    this.ville = null;
    this.f.city.setValue(null);
    if (!this.edit) {
      this.ville = this.citys.find(item => {
        if (item.uuid === value) {
          this.f.ville.setValue(item.libelle);
          return item;
        }
      });
    }
    this.f.city.setValue(value);
  }
  onInputChange(): void {
    const input = this.form.get('libelle').value;

  }
  
  
  onSubmit() {
    this.submit = true;
    if (this.form.valid) {
      const form = this.form.value;
      this.commonService.add(form).subscribe(
        res => {
          if (res?.status === 'success') {
            this.modal.dismiss();
            this.modal.close('ferme');
            this.commonUpdated.emit();
            this.emitter.emit({action: this.edit ? 'QUARTIER_UPDATED' : 'QUARTIER_ADD', payload: res?.data});
          }
          this.emitter.stopLoading();
        },
        error => { });
    } else {
      this.toast('Votre formualire n\'est pas valide.', 'Attention !', 'warning');
      return;
    }
  }
  onConfirme() {
    Swal.fire({
      title: '',
      text: 'Confirmez-vous l\'enregistrement ?',
      icon: 'warning',
      showCancelButton: true,
      showCloseButton: true,
      confirmButtonText: 'Confirmer <i class="fas fa-check"></i>',
      cancelButtonText: 'Annuler <i class="feather icon-x-circle"></i>',
      confirmButtonColor: '#1bc943',
      reverseButtons: true
    }).then((willDelete) => {
      if (!willDelete.dismiss) {
        this.onSubmit();
      }
    });
  }

  onClose(){
    this.form.reset()
    this.modal.close('ferme');
  }
  toast(msg, title, type): void {
    if (type === 'info') {
      this.toastr.info(msg, title);
    } else if (type === 'success') {
      this.toastr.success(msg, title);
    } else if (type === 'warning') {
      this.toastr.warning(msg, title);
    } else if (type === 'error') {
      this.toastr.error(msg, title);
    }
  }
  get f() { return this.form.controls }
}

