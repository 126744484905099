import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EmitterService } from '@service/emitter/emitter.service';
import { VilleService } from '@service/ville/ville.service';
import { Globals } from '@theme/utils/globals';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { CountryService } from '@service/country/country.service';
import { Output, EventEmitter } from '@angular/core';
import { Country } from '@model/country';
import { Ville } from '@model/ville';

@Component({
  selector: 'app-city-add',
  templateUrl: './city-add.component.html',
  styleUrls: ['./city-add.component.scss']
})
export class CityAddComponent implements OnInit {
  type: string = ""
  title: string = ""
  countrySelected: any
  edit: boolean = false
  country: Country
  ville: Ville
  form: FormGroup
  submit: boolean = false
  required = Globals.required;
  formSearch: any;
  @Output() cityUpdated = new EventEmitter<void>();

  constructor(
    public modal: NgbActiveModal,
    public toastr: ToastrService,
    private formBuild: FormBuilder,
    private emitter: EmitterService,
    private countryService: CountryService,
    private villeService: VilleService
  ) {
    this.edit = this.countryService.edit
    this.ville = this.villeService.getVille()
    this.title = (!this.edit) ? "Ajouter une ville" : "Modifier la ville "+this.ville.libelle
    this.newForm()
  }

  ngOnInit(): void {
    this.editForm()
  }

  newForm() {
    this.form =  this.formBuild.group({
      uuid: [null],
      id: [null],
      country: [null, [Validators.required]],
      libelle: [null, [Validators.required]]
    })
  }
  editForm() {
    if (this.edit) {
      const data = { ...this.villeService.getVille() }
      this.countrySelected= {
        title: data.country?.nom ? data.country.nom : null,
        detail: data.country?.indicatif ? data.country.indicatif : null,
      }
    
      this.form.patchValue(data)  
      this.f.country.setValue(data?.country ? data?.country?.uuid : null);
      
    }
  }
  onInputChange(): void {
    const input = this.form.get('libelle').value;
    
  }

  setcountryUuid(uuid){
    if(uuid){
      this.f.country.setValue(uuid)
      this.loadCountry(uuid)
    }else{
      this.f.country.setValue(null)
      this.countrySelected = null
    }
  }
  loadCountry(uuid){
    if(uuid){
      this.countryService.getSingle(uuid).subscribe((res: any)=> {
        } , error => {}
      );
       this.form.get('libelle').reset();
    }
  }
  
  onSubmit() {
    this.submit = true;
    if (this.form.valid) {
      const form = this.form.value;
      this.villeService.add(form).subscribe(
        res => {
          if (res?.status === 'success') {
            this.modal.dismiss();
            this.modal.close('ferme');
            this.cityUpdated.emit();
            console.log(this.emitter.emit({action: this.edit ? 'CITY_UPDATED' : 'CITY_ADD', payload: res?.data}));
          }
          this.emitter.stopLoading();
        },
        error => { });
    } else {
      this.toast('Votre formualire n\'est pas valide.', 'Attention !', 'warning');
      return;
    }
  }
  onConfirme() {
    Swal.fire({
      title: '',
      text: 'Confirmez-vous l\'enregistrement ?',
      icon: 'warning',
      showCancelButton: true,
      showCloseButton: true,
      confirmButtonText: 'Confirmer <i class="fas fa-check"></i>',
      cancelButtonText: 'Annuler <i class="feather icon-x-circle"></i>',
      confirmButtonColor: '#1bc943',
      reverseButtons: true
    }).then((willDelete) => {
      if (!willDelete.dismiss) {
        this.onSubmit();
      }
    });
  }
  
  onClose(){
    this.form.reset()
    this.modal.close('ferme');
  }
  toast(msg, title, type): void {
    if (type === 'info') {
      this.toastr.info(msg, title);
    } else if (type === 'success') {
      this.toastr.success(msg, title);
    } else if (type === 'warning') {
      this.toastr.warning(msg, title);
    } else if (type === 'error') {
      this.toastr.error(msg, title);
    }
  }
  get f() { return this.form.controls }
}
