import { map } from 'rxjs/operators';
import { Ville } from "../../model/ville";
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
import { ApiService } from '@theme/utils/api.service';
import { ApiUrlService } from '@theme/utils/api.service';
import { NoInternetHelper } from '@theme/utils/no-internet-helper';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class VilleService {
  ville: Ville;
  public edit: boolean = false;
  public type: string = '';
  private url = "admin/city";

  constructor(private api : ApiUrlService) { }


  setville(ville: Ville) {
    this.ville = ville
  }

  getVille(): Ville {
    return this.ville
  }

  add(data: Ville): Observable<any> {
    if (!navigator.onLine) {
      NoInternetHelper.internet()
      return Observable.create(obs => {
        obs.next();
        obs.complete();
      });
    }

    if (this.edit) {
      return this.update(data);
    } else {
      return this.create(data);
    }
  }

  create(data:Ville): Observable<any> {
    return this.api._post(`${this.url}/new`, data).pipe(
      map((response: any) => response),
      catchError((error: any) => throwError(error))
    );
  }

  update(data:Ville): Observable<any> {
    return this.api._post(`${this.url}/${data.uuid}/edit`, data).pipe(
      map((response: any) => response),
      catchError((error: any) => throwError(error))
    );
  }
  getList(): Observable<Ville[]> {
    if (!navigator.onLine) {
      NoInternetHelper.internet();
      return new Observable((observer) => {
        observer.next([]);  // Retourne un tableau vide hors ligne
        observer.complete();
      });
    }
  
    return this.api._get(`${this.url}/`)
      .pipe(
        map((response: any) => {
          return response ?? [];  
        }),
        catchError((error: any) => {
          console.error();
          return throwError(error);
        })
      );
  }
  getSingle(uuid: string): Observable<Ville> {
    if (!navigator.onLine) {
      NoInternetHelper.internet()
      return Observable.create(obs => {
        obs.next();
        obs.complete();
      });
    }

    return this.api._get(`${this.url}/show`, {uuid: uuid}).pipe(
      map((response: any) => response.data),
      catchError((error: any) => throwError(error))
    );
  }

  getDelete(id: string): Observable<any> {
    if (!navigator.onLine) {
      NoInternetHelper.internet()
      return Observable.create(obs => {
        obs.next();
        obs.complete();
      });
    }

    return this.api._delete(`${this.url}/${id}/delete`).pipe(
      map((response: any) => response),
      catchError((error: any) => throwError(error))
    );
  }
}

